<template>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c18 font-c5 text-left mb-6">
          <div class="w-1/6">
            Nº DE RÉF.
          </div>
          <div class="w-1/5 ml-4">
            CLIENT
          </div>
          <div class="w-1/6 ml-4">
            EDITION
          </div>
          <div class="w-1/6 ml-4">
            LIVRAISON
          </div>
          <div class="w-1/6 ml-4 text-left">
            STATUTS
          </div>
          <div class="w-1/6 ml-4">
            MONTANT
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div v-if="tracker.length === 0 && !charge" class="mt-10 text-center text-60">
          Aucune commande disponible dans cette catégorie
        </div>


        <div
          v-for="(item, index) in tracker"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="prev"

        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste,
  },

  props: {
    filtre: {
      type: String,
      default: null
    },

    searchType: {
      type: String,
      default: null
    },

    search: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        prev,
        next
      },
      devis: [],
      charge: true,
      temoin: null,

      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      allOrderPaginate: [],
      date: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allOrderPaginate

      if (this.filtre !== this.temoin){
        let status = 'COORDINATION'
        if (this.filtre === 'Rejetées') {
          status = 'COORDINATION_REJECT'
          this.allCommandeFiltre(status, 1)
        }
        this.$emit('user', retour.length)
      }

      if (this.filtre !== 'Rejetées' && this.filtre !== 'Filtrer ici' && this.filtre !== null || this.search !== null && this.search !== ''){
        if (this.devis.length > 0){
          retour = this.devis
        }

        if (this.filtre !== 'Rejetées' && this.filtre !== null) {
          if (this.filtre !== this.temoin) {
            this.allCommande()
          }

          if (this.filtre === 'CNSNL'){
            retour = retour.filter((item) => {
              if (item.status === this.filtre) {
                return item
              }
            })
          }

          if (this.filtre === 'CSNL'){
            retour = retour.filter((item) => {
              if (item.status === this.filtre) {
                return item
              }
            })
          }

          if (this.filtre === 'CSL'){
            retour = retour.filter((item) => {
              if (item.status === this.filtre) {
                return item
              }
            })
          }

          if (this.filtre === 'CNSL'){
            retour = retour.filter((item) => {
              if (item.status === this.filtre) {
                return item
              }
            })
          }
        }


        if (this.search !== null && this.search !== '') {

          const type = this.search.toLowerCase()
          retour = retour.filter((item) => {
              if (
                  (item.reference.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                  ((item.estimate.client.name + ' ' + item.estimate.client.surname).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                  ((item.estimate.client.surname + ' ' + item.estimate.client.name).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1
                  ) {
                return item
              }

            // Use Search type
            if (this.searchType !== 'Type de recherche' && this.searchType !== 'Aucun') {
              if (this.searchType === 'Date d\'édition' &&
                  (new Date(item.created_at).toLocaleDateString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }

              if (this.searchType === 'Date de livraison' &&
                  (new Date(this.expiration(item.created_at)).toLocaleDateString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
            }
          })
        }
        this.$emit('user', retour.length)
      }


      return retour
    }
  },

  created () {
    this.allCommandeFiltre()
    this.allCommande()
    this.temoin = this.filtre
    this.$store.dispatch('saveTemporalItem', null)
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allCommandeFiltre()
      }
    },

    expiration (item) {
      const temps = 10 * (1000 * 3600 * 24)
     return new Date(item).getTime() + temps
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allCommandeFiltre()
      }
    },

    allCommande () {
      this.charge = true
      this.temoin = this.filtre

      http.get(apiroutes.baseURL + apiroutes.getCommande)
          .then(response => {
            this.devis = response
            this.charge = false
            this.temoin = this.filtre
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.status === 401) {
              window.location.reload()
            }
          })
    },

    allCommandeFiltre (status, page) {
        this.temoin = this.filtre
        this.charge = true
        const body = {
          page: this.page,
          limit: this.limite,
        }
        if (status !== undefined) {
          body.state = status
        }
        if (page !== undefined) {
          body.page = page
        }

        http.post(apiroutes.baseURL + apiroutes.orderPaginate, body)
            .then(response => {
              console.log(response)
              this.allOrderPaginate = response.data
              this.charge = false
              this.total = response.total
              const page = parseInt(this.total)/parseInt(this.limite)

              if (page > parseInt(page)) {
                this.totalPage = parseInt(page) + 1
              } else {
                this.totalPage = parseInt(page)
              }
              this.$emit('user', this.total)
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
    },

    conversion (item) {
      return new Date(item).toLocaleDateString()
    },

    selected (item) {
      const url = window.location.origin + '/detailsCommande?x=' + item.id
      window.open(url, "_blank");
      // this.$router.push({ path: '/detailsCommande', query: { x: item.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
