<template>
  <div class="p-10 pr-16 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4">
            Commandes <span v-if="user !== null">({{ user }})</span>
          </div>
        </div>

        <div class="w-1/2 flex justify-end">
          <div class="w-1/3 mr-6">
                <multiselect
                    :icon="icons.filter"
                    height="54px"
                    background="white"
                    :icon-width="25"
                    width="12%"
                    :value-t="valueFiltre"
                    :option="filtre"
                    @info="retourFiltre"
                />
          </div>

          <div class=" w-1/3">
              <bouton
                label="Faire un devis"
                radius="5.4px"
                weight="600"
                size="17px"
                height="54px"
                @oga="retourClick"
              />
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-6">
        <div class="w-1/4 mr-6">
          <multiselect
              :icon="icons.filter"
              height="54px"
              background="white"
              :icon-width="25"
              width="12%"
              :value-t="valueSearchType"
              :option="searchType"
              @info="retourSearchType"
          />
        </div>

        <div class="w-1/3">
          <inputo :icon="icons.search" @info="retourSearch" height="56px" placeholder="Votre recherche ici" />
        </div>
      </div>

      <div class="mt-6">
        <global-view
            :filtre="valueFiltre"
            :searchType="valueSearchType"
            :search="recherche"
          v-if="!reloadSession"
          @info="retourEtape"
          @user="retourUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/commandes/globalData'
import filter from '../../assets/icons/filter.svg'
import multiselect from '../../component/helper/form/multiselect'
import inputo from '../../component/helper/form/input'
import search from '../../assets/icons/search.svg'

export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        back,
        filter,
        search
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      filtre: ['Rejetées', 'CNSNL', 'CSNL', 'CSL'],
      valueFiltre: 'Filtrer ici',
      recherche: '',
      searchType: ['Aucun', 'Date d\'édition', 'Date de livraison'],
      valueSearchType: 'Type de recherche',
    }
  },

  created () {
    this.$store.dispatch('saveQuote', null)
    this.$store.dispatch('saveBasket', [])
    this.$store.dispatch('saveCoupon', null)
    this.$store.dispatch('saveUpdate', null)
    this.$store.dispatch('saveDevisUser', null)
  },

  methods: {
    retourSearch (answer) {
      this.recherche = answer
    },

    retourClick () {
      this.$router.push('/nouveauDevis')
    },

    retourFiltre (answer) {
      this.valueFiltre = answer
    },

    retourUser (answer) {
      this.user = answer
    },

    retourSearchType (answer) {
      this.valueSearchType = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all {
  min-height: 100vh;
}
</style>
